<template>
  <v-container v-if='info' fluid>
    <!-- --------------------------------------------------------- -->
    <!-- ----------------- Filtro por data ----------------------- -->
    <!-- --------------------------------------------------------- -->
    <v-col>
      <v-row>
        <span class='subtitle-2'>{{ $t('PERIOD') }}</span>
      </v-row>
      <v-row>
        <v-chip-group
          v-model='filterChip'
          style='width: 100%'
          outlined
          column
          mandatory
          active-class='selected-chip'
          @change='changeDateFilter'
        >
          <v-chip
            v-for='filter in filters'
            :key='filter.text'
            :active='true'
            outlined
            color='secondary'
            class='mr-5'
          >
            {{filter.text}}
          </v-chip>
        </v-chip-group>
      </v-row>
    </v-col>
    <v-col v-show='showFilters' cols='12'>
      <v-row class='mt-4 d-flex align-center'>
        <v-col cols='5'>
          <v-row class='d-flex align-center'>
            <span class='mr-5'>{{ $t('FROM') }}: </span>
            <CustomDatePicker class='pt-0' @change='setStartDate' />
          </v-row>
        </v-col>
        <v-col cols='5'>
          <v-row class='d-flex align-center'>
            <span class='mr-5 mb-0 pb-0'>{{ $t('UNTIL') }}: </span>
            <CustomDatePicker class='pt-0' @change='setEndDate' />
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <!-- ------------------------------------------------- -->
    <!-- Total de registros e botão de refresh -->
    <!-- ------------------------------------------------- -->
    <v-row no-gutters>
      <v-card flat class='wd-100'>
        <v-card-title>
          <v-row no-gutters>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon x-large color='primary'>
                  mdi-account-box-outline
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{$t('NUMBER_OF_ENTRIES', { value: info.data.value })}}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{$t('NUMBER_OF_ENTRIES_COMPLETED_PENDING', { completed: info.data.completed, pending: info.data.pending })}}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-row>

          <v-spacer />

          <v-btn icon color='primary' class='ml-3 mt-1' @click='getData'>
            <v-icon large>
              mdi-refresh
            </v-icon>
          </v-btn>
        </v-card-title>
      </v-card>
    </v-row>

    <!-- ------------------------------------------------------ -->
    <!-- Gráfico de linhas com o número de entradas em cada dia -->
    <!-- ------------------------------------------------------ -->
    <!-- <v-row v-if='info.countByDay && chartLine' no-gutters class='mt-4'>
      <v-card flat class='wd-100'>
        <apexchart type='line' height='500' :options='chartLineOptions' :series='chartLineData' />
      </v-card>
    </v-row> -->

    <!-- ------------------------------------------------- -->
    <!-- Cards/Gráfico de pizza com o número de nfiq_score -->
    <!-- ------------------------------------------------- -->
    <v-row no-gutters align='center' class='mt-5'>
      <div class='text-h5'>
        {{$t('BIOMETRIC_CATCHES')}}
      </div>
    </v-row>
    <v-divider class='my-3' />

    <v-row v-if='info.nfiqData && chartPie && hasData' no-gutters class='mt-4'>
      <v-col sm='12' md='6'>
        <v-row no-gutters>
          <v-card flat class='wd-100 mr-4'>
            <v-list-item>
              <v-list-item-avatar tile size='48.5' class='success--text font-weight-medium'>
                <v-tooltip right>
                  <template #activator='{ on }'>
                    <span v-on='on'>{{info.nfiqData.one}}</span>
                  </template>
                  {{info.nfiqData.one}}
                </v-tooltip>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class='text-center text-h6'>
                  {{$t('NFIQ_VERY_GOOD')}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-row>
        <v-row no-gutters>
          <v-card flat class='wd-100 mt-2 mr-4'>
            <v-list-item>
              <v-list-item-avatar tile size='48.5' class='info--text font-weight-medium'>
                <v-tooltip right>
                  <template #activator='{ on }'>
                    <span v-on='on'>{{info.nfiqData.two}}</span>
                  </template>
                  {{info.nfiqData.two}}
                </v-tooltip>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class='text-center text-h6'>
                  {{$t('NFIQ_GOOD')}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-row>
        <v-row no-gutters>
          <v-card flat class='wd-100 mt-2 mr-4'>
            <v-list-item>
              <v-list-item-avatar tile size='48.5' class='font-weight-medium'>
                <v-tooltip right>
                  <template #activator='{ on }'>
                    <span v-on='on'>{{info.nfiqData.three}}</span>
                  </template>
                  {{info.nfiqData.three}}
                </v-tooltip>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class='text-center text-h6'>
                  {{$t('NFIQ_NORMAL')}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-row>
        <v-row no-gutters>
          <v-card flat class='wd-100 mt-2 mr-4'>
            <v-list-item>
              <v-list-item-avatar tile size='48.5' class='warning--text font-weight-medium'>
                <v-tooltip right>
                  <template #activator='{ on }'>
                    <span v-on='on'>{{info.nfiqData.four}}</span>
                  </template>
                  {{info.nfiqData.four}}
                </v-tooltip>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class='text-center text-h6'>
                  {{$t('NFIQ_BAD')}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-row>
        <v-row no-gutters>
          <v-card flat class='wd-100 mt-2 mr-4'>
            <v-list-item>
              <v-list-item-avatar tile size='48.5' class='error--text font-weight-medium'>
                <v-tooltip right>
                  <template #activator='{ on }'>
                    <span v-on='on'>{{info.nfiqData.five}}</span>
                  </template>
                  {{info.nfiqData.five}}
                </v-tooltip>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class='text-center text-h6'>
                  {{$t('NFIQ_VERY_BAD')}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-row>
      </v-col>
      <v-col sm='12' md='6'>
        <v-card flat class='wd-100'>
          <v-card-text>
            <v-row align='center' justify='center'>
              <apexchart :key='chartKey' type='donut' width='500' :options='chartPieOptions' :series='chartPieData' />
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-col v-if='!hasData' class='d-flex justify-center'>
      <span>
        {{ $t('NO_DATA') }}
      </span>
    </v-col>

    <v-snackbar
      v-model='snackbarBeta'
      color='red lighten-1'
      :timeout='5000'
    >
      {{$t('BETA_WARNING')}}

      <template #action='{ attrs }'>
        <v-btn
          text
          v-bind='attrs'
          @click='snackbarBeta = false'
        >
          {{$t('OK')}}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import { format, parseISO } from 'date-fns';
  import ptBr from 'date-fns/locale/pt-BR';

  export default {
    components: {
      CustomDatePicker: () => import('../../components/DatePicker/CustomDatePicker.vue'),
    },
    name: 'Home',
    data: function () {
      return {
        filterChip: 2,
        snackbarBeta: true,
        info: undefined,
        chartLine: false,
        chartLineData: undefined,
        chartLineOptions: {
          chart: {
            height: 500,
            type: 'line',
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: true,
          },
          stroke: {
            width: [5, 7, 5],
            curve: 'straight',
            dashArray: [0, 8, 5],
          },
          legend: {
            tooltipHoverFormatter: function (val, opts) {
              return `${val} - ${opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]}`;
            },
          },
          markers: {
            size: 5,
            hover: {
              sizeOffset: 6,
            },
          },
          xaxis: {
            categories: undefined,
          },
          tooltip: {
            y: [
              {
                title: {
                  formatter: function (val) {
                    return `${val}`;
                  },
                },
              },
              {
                title: {
                  formatter: function (val) {
                    return `${val}`;
                  },
                },
              },
              {
                title: {
                  formatter: function (val) {
                    return val;
                  },
                },
              },
            ],
          },
          grid: {
            borderColor: '#f1f1f1',
          },
        },
        chartPie: false,
        chartPieData: undefined,
        chartPieOptions: {
          chart: {
            width: 500,
            type: 'pie',
          },
          labels: undefined,
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          }],
        },
        filters: [
          {
            text: this.$t('TODAY'),
            value: 'today',
            active: false,
          },
          {
            text: this.$t('X_DAYS', { value: 3 }),
            value: '3days',
            active: true,
          },
          {
            text: this.$t('X_DAYS', { value: 7 }),
            value: '7days',
          },
          {
            text: this.$t('X_DAYS', { value: 15 }),
            value: '15days',
          },
          {
            text: this.$t('X_DAYS', { value: 30 }),
            value: '30days',
          },
          {
            text: this.$t('CUSTOM'),
            value: 'custom',
          },
        ],
        showFilters: false,
        startDate: null,
        endDate: null,
        startDateFromPicker: null,
        showStartDatePicker: null,
        endDateFromPicker: null,
        hasData: true,
        chartKey: 1,
      };
    },
    mounted: function () {
      this.getData();
    },
    methods: {
      formattedDateMonth: function (value) {
        return value && format(parseISO(value), 'dd MMM', { locale: ptBr });
      },
      loadChartData: function () {
        if (!this.info || (this.info && !this.info.nfiqData)) {
          this.chartPie = false;
          this.chartPieOptions.labels = undefined;
          this.chartPieData = undefined;
        } else {
          const {
            one, two, three, four, five,
          } = this.info.nfiqData;
          if ((parseInt(this.info.nfiqData.one, 10) + parseInt(this.info.nfiqData.two, 10) + parseInt(this.info.nfiqData.three, 10) + parseInt(this.info.nfiqData.four, 10) + parseInt(this.info.nfiqData.five, 10)) === 0) {
            this.hasData = false;
          } else {
            this.chartPieOptions.labels = [this.$t('NFIQ_VERY_GOOD'), this.$t('NFIQ_GOOD'), this.$t('NFIQ_NORMAL'), this.$t('NFIQ_BAD'), this.$t('NFIQ_VERY_BAD')];
            this.chartPieData = [parseInt(one, 10), parseInt(two, 10), parseInt(three, 10), parseInt(four, 10), parseInt(five, 10)];
            this.chartPie = true;
            this.hasData = true;
            this.chartKey++;
          }
        }
      },
      getData: async function () {
        try {
          this.chart = false;
          if (!this.startDate || !this.endDate) {
            const today = new Date();
            this.endDate = `${today.toISOString().split('T')[0]} 23:59:59`;
            today.setDate(today.getDate() - 7); // inicia como filtro padrão de 7 dias
            this.startDate = `${today.toISOString().split('T')[0]} 00:00:00`;
          }
          const { data } = await axios({
            url: '/entry-get-data-info',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
            params: {
              endDate: this.endDate,
              startDate: this.startDate,
            },
          });

          this.info = data;

          this.loadChartData();
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      changeDateFilter (index) {
        const today = new Date();
        switch (this.filters[index].value) {
          case 'custom':
            this.showFilters = true;
            break;
          case 'today':
            this.showFilters = false;
            this.startDate = `${today.toISOString().split('T')[0]} 00:00:00`;
            this.endDate = `${today.toISOString().split('T')[0]} 23:59:59`;
            this.getData();
            break;
          case '3days':
            this.showFilters = false;
            this.endDate = `${today.toISOString().split('T')[0]} 23:59:59`;
            today.setDate(today.getDate() - 3);
            this.startDate = `${today.toISOString().split('T')[0]} 00:00:00`;
            this.getData();
            break;
          case '7days':
            this.showFilters = false;
            this.endDate = `${today.toISOString().split('T')[0]} 23:59:59`;
            today.setDate(today.getDate() - 7);
            this.startDate = `${today.toISOString().split('T')[0]} 00:00:00`;
            this.getData();
            break;
          case '15days':
            this.showFilters = false;
            this.endDate = `${today.toISOString().split('T')[0]} 23:59:59`;
            today.setDate(today.getDate() - 15);
            this.startDate = `${today.toISOString().split('T')[0]} 00:00:00`;
            this.getData();
            break;
          case '30days':
            this.showFilters = false;
            this.endDate = `${today.toISOString().split('T')[0]} 23:59:59`;
            today.setDate(today.getDate() - 30);
            this.startDate = `${today.toISOString().split('T')[0]} 00:00:00`;
            this.getData();
            break;
          default:
            this.showFilters = false;
            break;
        }
      },
      setStartDate (date) {
        this.startDateFromPicker = `${date} 00:00:00`;
        this.startDate = `${date} 00:00:00`;
        this.showStartDatePicker = false;
        if (this.endDateFromPicker) {
          this.getData();
        }
      },
      setEndDate (date) {
        this.endDateFromPicker = `${date} 23:59:59`;
        this.endDate = `${date} 23:59:59`;
        this.showStartDatePicker = false;
        if (this.startDateFromPicker) {
          this.getData();
        }
        this.showEndDatePicker = false;
      },
    },
  };
</script>

<style scoped>
  .selected-chip {
    background-color: var(--v-accent-base) !important;
  }
</style>
